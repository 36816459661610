// BASE_URL on server
export const BASE_URL = 'https://routeeld.uz';
// export const BASE_URL = 'http://localhost:7001';
export const MOCK_TOKEN = 'token';
export const LANG = 'en';

export default {
    LANG,
    MOCK_TOKEN,
    BASE_URL,
};  