import { RoleResponse } from '@/models/user/RoleResponse';
import { UserResponse } from '@/models/user/UserResponse';
import { reactive, ref } from 'vue';

class UserService {
    private _user = ref<UserResponse | null>(null);
    private _token: string | null = null;

    constructor() {
        this.loadUserFromStorage();
    }

    private loadUserFromStorage(): void {
        const userString = localStorage.getItem('user');
        if (userString) {
            const user = JSON.parse(userString) as UserResponse;
            this._user.value = reactive(user);
        }
    }

    // Method to get the current user
    public get user(): UserResponse | null {
        return this._user.value;
    }

    // Method to check if the user is authenticated
    public isAuthenticated(): boolean {
        return !!this._user && !!this._token;
    }

    // Method to get user roles
    public getUserRoles(): string[] {
        return this._user.value?.roles.map((role: RoleResponse) => role.name) || [];
    }
}

export const userService = new UserService();
