import { userService } from "@/features/UserService";
import { computed } from "vue";

function getCarrierId(): number | null {
    const jsonId = localStorage.getItem('selectedCarrierId');

    const carrierId = jsonId ? Number(JSON.parse(jsonId)) : null;
    return carrierId;
}

function setCarrierId(carrierId: number): void {
    localStorage.setItem('selectedCarrierId', carrierId.toString());
}

function findCarrierId(): number | null {
    const currentUser = computed(() => userService.user);
    if (currentUser.value?.carrierId && currentUser.value.carrierId > 0) {
        return currentUser.value.carrierId;
    }
    let carrierId = getCarrierId();
    if (carrierId && carrierId > 0) {
        return carrierId;
    }

    return null;
}

export { getCarrierId, setCarrierId, findCarrierId };