import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "notify" }

import {ref, onMounted, onUnmounted} from 'vue';
import {nanoid} from 'nanoid';
import {NotificationsOptions, Notification} from './types';
import emitter from './emitters';
import NotificationItem from './NotificationItem.vue';

type NotificationsTimeouts = Record<string, number>;

const DEFAULT_DURATION = 10000;

export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsComponent',
  setup(__props) {

const notifications = ref<Notification[]>([]);
const notificationsTimers: NotificationsTimeouts = {};

const closeNotification = (id: string) => {
  if (!id) return;
  const index = notifications.value.findIndex((notification) => notification.id === id);
  if (index > -1) {
    notifications.value.splice(index, 1);
  }
};

const addNotification = (item: NotificationsOptions) => {
  if (notifications.value.find((notification) => notification.text === item.text)) {
    return;
  }

  const id = nanoid(10);
  const notification: Notification = {
    id,
    text: item.text || '',
    type: item.type,
  };
  notifications.value.push(notification);
  notificationsTimers[id] = setTimeout(
      () => closeNotification(notification.id),
      item.duration || DEFAULT_DURATION,
  );
};

const handleHover = (id: string) => {
  if (notificationsTimers[id]) {
    clearTimeout(notificationsTimers[id]);
  }
};
const handleHoverLeave = (id: string) => {
  if (notificationsTimers[id]) {
    clearTimeout(notificationsTimers[id]);
  }
  notificationsTimers[id] = setTimeout(() => closeNotification(id), DEFAULT_DURATION);
};

onMounted(() => {
  emitter.on('add', addNotification);
  emitter.on('close', closeNotification);
});

onUnmounted(() => {
  notifications.value = [];
  Object.values(notificationsTimers).forEach((timer) => {
    if (timer) {
      clearTimeout(timer);
    }
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "notification" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications.value, (item) => {
          return (_openBlock(), _createBlock(NotificationItem, {
            key: item.id,
            "notification-item": item,
            onClose: closeNotification,
            onHover: handleHover,
            onHoverLeave: handleHoverLeave
          }, null, 8, ["notification-item"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})