import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notification" }
const _hoisted_2 = { class: "text" }

import {computed,} from 'vue';
import {Notification, NotificationType} from './types';

interface NotificationProps {
  notificationItem: Notification,
}

interface NotificationEmits {
  (e: 'close', itemID: string): void

  (e: 'hover', itemID: string): void

  (e: 'hover-leave', itemID: string): void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationItem',
  props: {
    notificationItem: {}
  },
  emits: ["close", "hover", "hover-leave"],
  setup(__props: any) {



const props = __props;
const typeClass = computed(() => {
  switch (props.notificationItem.type) {
    case NotificationType.ERROR:
      return 'error-message';
    case NotificationType.SUCCESS:
      return 'success-message';
    default:
      return '';
  }
});

const isLongText = computed(() => props.notificationItem.text.length > 40);


return (_ctx: any,_cache: any) => {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["close-icon-body", typeClass.value]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close', _ctx.notificationItem.id)))
    }, [
      _createVNode(_component_a_icon),
      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "feather icon-x" }, null, -1))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["notification-content", [typeClass.value, { 'long-text': isLongText.value }]]),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('hover', _ctx.notificationItem.id))),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('hover-leave', _ctx.notificationItem.id)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["icon-body", typeClass.value])
      }, [
        _createVNode(_component_a_icon)
      ], 2),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.notificationItem.text), 1),
      _createElementVNode("div", {
        class: "close-icon-responsive",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close', _ctx.notificationItem.id)))
      }, [
        _createVNode(_component_a_icon)
      ])
    ], 34)
  ]))
}
}

})