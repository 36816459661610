import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {watch} from 'vue';
import {useRoute} from 'vue-router';
import {Notifications} from '@/components/base/notifications';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();

watch(
    () => route.meta?.title,
    () => {
      document.title = (route.meta?.title as string) || 'Admin ';
    },
    {immediate: true},
);


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_unref(Notifications))
  ], 64))
}
}

})