/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, {AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig} from 'axios';
import {useCookies} from 'vue3-cookies';
import {addError} from '@/components/base/notifications';
import {BASE_URL, LANG} from '@/defaults';
import {ApiResponse} from "@/features/api.response";
import {ErrorResponse} from "@/features/ErrorResponse";
import router from "@/router";
import {authServiceInstance} from '@/features/auth/auth.service';

const getAxiosConfig = (config: AxiosRequestConfig) => {
    const updatedConfig = {...config};
    if (!updatedConfig.headers) {
        updatedConfig.headers = {};
    }

    const lang = localStorage.getItem('lang') || LANG;

    updatedConfig.headers['Accept-Language'] = lang;
    updatedConfig.headers.language = lang;
    const {cookies} = useCookies();
    updatedConfig.headers.Authorization = `Bearer ${cookies.get('token')}`;

    return updatedConfig as InternalAxiosRequestConfig;
};

const defaultErrorHandler = async (error: any, errorMessage?: string) => {
    const {response} = error;

    if (errorMessage) {
        addError(errorMessage);
        return response.data;
    }

    if (response.data == null) {
        addError(response.message || 'Something went wrong');
        return Promise.reject(error);
    }

    addError(response.data?.error?.message || 'Something went wrong');
    return response.data;
};

export class ApiService {
    private axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = Axios.create({
            baseURL: BASE_URL,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                device_os: 'web',
            },
        });

        this.axiosInstance.interceptors.request.use(
            getAxiosConfig,
            (error) => Promise.reject(error),
        );
    }

    public async sendRequest<T>(method: string, url: string, data?: any, params?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
        if (!url) {
            throw new Error('URL is required to make a request');
        }
        let result: any;

        try {
            switch (method.toLowerCase()) {
                case 'get':
                    result = await this.axiosInstance.get(url, {params, ...config});
                    break;
                case 'post':
                    result = await this.axiosInstance.post(url, data, config);
                    break;
                case 'put':
                    result = await this.axiosInstance.put(url, data, {params, ...config});
                    break;
                case 'delete':
                    result = await this.axiosInstance.delete(url, {params, ...config});
                    break;
                default:
                    throw new Error(`Unsupported HTTP method: ${method}`);
            }

            return result.data;

        } catch (error: any) {

            if (error?.response?.status === 401) {
                await authServiceInstance.logout();
                await router.push({name: 'login'});
                return Promise.reject(new Error('Unauthorized - Redirecting to login'));
            }

            if (error) {
                const errorResponse: ErrorResponse = error.response.data;
                if (errorResponse.message)
                    return defaultErrorHandler(error, errorResponse.message);
            }
            return defaultErrorHandler(error);
        }
    }
}

export const ApiServiceInstance = new ApiService();
