import { RoleResponse } from "@/models/user/RoleResponse";
import { useRoleStore } from "@/store/Role";
import { computed, onMounted } from "vue";

//do not deleted this is need function for checking user roles
export function useCan() {
    const roleStore = useRoleStore();
    onMounted(async () => await roleStore.getAll());
    const databaseRoles = computed(() => roleStore.roles);
}

export function hasRoleAccess(roleName: string): boolean {
    const currentRoles = getCurrentUserRoles();
    return currentRoles.some(role => role.name === roleName);
}

export function hasPermissionAccess(permissionName: string): boolean {
    const currentRoles = getCurrentUserRoles();
    const hasAccess = currentRoles.some(role =>
        role.permissions.some(permission => permission.name === permissionName)
    );

    return hasAccess;
}


function getCurrentUserRoles(): RoleResponse[] {
    const jsonRoles = localStorage.getItem('userRoles');
    const userRoles: RoleResponse[] = jsonRoles ? parseRoles(JSON.parse(jsonRoles)) : [];
    return userRoles;
}

function parseRoles(jsonRoles: any[]): RoleResponse[] {
    if (!Array.isArray(jsonRoles)) {
        return [];
    }

    var roles = jsonRoles.map(jsonRole => {
        return {
            id: jsonRole.id,
            name: jsonRole.name,
            group: jsonRole.group,
            permissions: Array.isArray(jsonRole.permissions)
                ? jsonRole.permissions.map((permission: any) => ({
                    id: permission.id,
                    name: permission.name
                }))
                : []
        };
    });

    return roles;
}