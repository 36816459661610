import mitt from 'mitt';
import {NotificationsOptions} from './types';

type NotificationEvent = {
    add: NotificationsOptions,
    close: string,
}

const emitter = mitt<NotificationEvent>();
export default emitter;
