function getProviderId(): number | null {
    const jsonId = localStorage.getItem('selectedProviderId');

    const providerId = jsonId ? Number(JSON.parse(jsonId)) : null;
    return providerId;
}

function setProviderId(ProviderId: number): void {
    localStorage.setItem('selectedProviderId', ProviderId.toString());
}

export { getProviderId, setProviderId };