import { ref } from "vue";
import { defineStore } from 'pinia';
import { ApiUrls } from "@/features/apiUrls";
import { ApiMethods } from "@/features/api.methods";
import type { Role } from "@/features/interfaces/role";
import { ApiServiceInstance } from '@/features/api.service';
import { RoleResponse } from "@/models/user/RoleResponse";

export const useRoleStore = defineStore('Role', () => {
    const roles = ref<RoleResponse[]>([]);
    const role = ref<RoleResponse>();

    async function getAll() {
        let list = await ApiServiceInstance.sendRequest<RoleResponse[]>(ApiMethods.GET, ApiUrls.FETCH_ROLES);
        if (list.successResult) {
            roles.value = list.successResult;
        }
    }

    async function add(model: Role): Promise<boolean> {
        console.log(model.name)
        let result = await ApiServiceInstance.sendRequest<RoleResponse>(ApiMethods.POST, ApiUrls.ADD_ROLE, model);
        if (result.successResult) {
            role.value = result.successResult;
            return true;
        }
        return false;
    }

    async function update(id: number, model: Role): Promise<boolean> {
        let result = await ApiServiceInstance.sendRequest<RoleResponse>(ApiMethods.PUT, `${ApiUrls.UPDATE_ROLE}/${id}`, model);
        if (result.successResult) {
            role.value = result.successResult;
            return true;
        }
        return false;
    }

    async function get(id: number) {
        let result = await ApiServiceInstance.sendRequest<RoleResponse>(ApiMethods.GET, `${ApiUrls.GET_ROLE}/${id}`);
        if (result.successResult)
            role.value = result.successResult;
    }

    async function remove(id: number) {
        let result = await ApiServiceInstance.sendRequest<RoleResponse>(ApiMethods.DELETE, `${ApiUrls.DELETE_ROLE}/${id}`);
        if (result.successResult)
            role.value = result.successResult;
    }

    return { roles, role, getAll, add, update, remove, get }
});