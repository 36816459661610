import { createPinia } from "pinia";
import PrimeVue from 'primevue/config';
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "@/plugins/vee-validate";
import '@/views/Edit/EditHeader.scss';
import "flatpickr/dist/flatpickr.min.css";

import {
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Divider,
    Drawer,
    Dropdown,
    Empty,
    FloatButton,
    Form,
    Input,
    InputNumber,
    Layout,
    Modal,
    Popover,
    Progress,
    Result,
    Row,
    Segmented,
    Select,
    Spin,
    Table,
    Tag,
    Upload
} from "ant-design-vue";

const pinia = createPinia();
createApp(App)
    .use(pinia)
    .use(router)
    .use(Layout)
    .use(Collapse)
    .use(Button)
    .use(Spin)
    .use(Result)
    .use(Card)
    .use(Divider)
    .use(Col)
    .use(Row)
    .use(Drawer)
    .use(Modal)
    .use(Table)
    .use(Form)
    .use(Segmented)
    .use(InputNumber)
    .use(Input)
    .use(Tag)
    .use(DatePicker)
    .use(Select)
    .use(Checkbox)
    .use(Empty)
    .use(Progress)
    .use(FloatButton)
    .use(Dropdown)
    .use(Popover)
    .use(Upload)
    .use(PrimeVue, {
        unstyled: true
    })
    .mount("#app");
