import { ApiMethods } from '@/features/api.methods';
import { ApiServiceInstance } from '@/features/api.service';
import { ApiUrls } from '@/features/apiUrls';
import { LoginResponse } from '@/models/auth/LoginResponse';
import router from '@/router/index';
import { setCarrierId } from '@/utils/carrier.helpers';
import { setProviderId } from '@/utils/provider.helpers';
import { hasRoleAccess } from '@/utils/role.permission.helpers';
import { useCookies } from 'vue3-cookies';
import { Login } from '../interfaces/Login';

const { cookies } = useCookies();

const setToken = (token: string | undefined) => {
    if (!token) {
        console.error('Cannot set undefined token');
        return;
    }
    cookies.set('token', token);
};

export class AuthService {
    private async refreshToken() {
        return true;
    }

    public async login(model: Login) {
        let result = await ApiServiceInstance.sendRequest<LoginResponse>(ApiMethods.POST, ApiUrls.LOGIN_URL, model);
        if (result && result.successResult) {
            var response = result.successResult;
            setToken(response?.token);

            localStorage.setItem('userRoles', JSON.stringify(response?.user.roles));
            localStorage.setItem('user', JSON.stringify(response?.user));

            if (response.user.carrierId && response.user.carrierId > 0) {
                setCarrierId(response.user.carrierId);
            }
            if (response.user.serviceProviderId && response.user.serviceProviderId > 0) {
                setProviderId(response.user.serviceProviderId);
            }

            // if (hasRoleAccess('AppAdmin') || hasRoleAccess('CarrierSupport') || hasRoleAccess('CarrierManager')) {
            //     await router.push({ name: 'home' });
            // }
            if (hasRoleAccess('AppAdmin') || hasRoleAccess('ProviderAdmin')) {
                await router.push({ name: 'carrier-navigate' });
            }
        }
    }

    public async logout(): Promise<void> {
        cookies.remove('token');
        localStorage.removeItem('userRoles');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedCarrierId');
        localStorage.removeItem('selectedProviderId');
        await router.push({ name: 'login' });
    }
}

export const authServiceInstance = new AuthService();
