import { ApiMethods } from "@/features/api.methods";
import { ApiServiceInstance } from '@/features/api.service';
import { ApiUrls } from "@/features/apiUrls";
import type { CalculateDutyTimesRequest } from "@/models/hos/CalculateDutyTimesRequest";
import type { CalculateDutyTimesResponse } from "@/models/hos/CalculateDutyTimesResponse";
import { defineStore } from 'pinia';
import { ref } from "vue";

export const useHOSStore = defineStore('HOS', () => {
    const dutyTimes = ref<CalculateDutyTimesResponse>();

    async function graphData(model: DriverDateTimeRequest) {
        let result = await ApiServiceInstance.sendRequest<CalculateDutyTimesResponse>(ApiMethods.POST, ApiUrls.GET_HOS_GRAPH, model);
        if (result.successResult) {
            dutyTimes.value = result.successResult;
        }
    }

    function resetDutyTimes() {
        dutyTimes.value = undefined;
    }

    return { dutyTimes, graphData, resetDutyTimes }
});
